import React from "react"
import { graphql, navigate, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Container,
  ContentBox,
  Grid,
  P,
  H2,
} from "../components/styledcomponents"
import globals from "../components/globals"

const BlogImg = styled(GatsbyImage)`
  transition: transform 0.3s;
  position: relative;
`

const BlogTitle = styled(H2)`
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 1em;
  margin-top: 0;
`

const PostGrid = styled(Grid)`
  &:hover ${BlogImg} {
    transform: scale(1.5);
  }

  &:hover ${BlogTitle} {
    color: ${globals.color.darkAccent};
  }
`

const BlogNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2em;
  max-width: ${globals.media.tablet};
  width: 100%;
`

const BlogPrev = styled(Link)`
  margin-left: auto;
`

export default class BlogList extends React.Component {
  render() {
    const { currentPage, numPages } = this.props.pageContext
    const title =
      currentPage === 0
        ? "Kampfsport & Yoga News"
        : `Kampfsport & Yoga News, Seite ${currentPage}`
    const description =
      currentPage === 0
        ? "Hier veroffentlichen wir Neuigkeiten zu den Themen BJJ, MMA, Selbsverteidigung und Yoga aus unserem Studio in München Feldmoching."
        : `Seite ${currentPage} unserer Kampfsport und Yoga News`
    return (
      <Layout>
        <SEO title={title} description={description} />
        <Container>
          <h1>Kampfsport & Yoga News</h1>
          {console.log(`${currentPage}, ${numPages}`)}
          {this.props.data.allMarkdownRemark.edges.map(({ node }) => (
            <PostGrid
              backgroundColor="white"
              columns="1fr 2fr"
              columnsTablet="1fr 2fr"
              columnsMobile="1fr"
              radius="8px"
              key={node.id}
              onClick={() => navigate(`/blog/${node.frontmatter.slug}/`)}
            >
              {node.frontmatter.featuredImage && (
                <ContentBox width="100%" padding="0" margin="0" radius="0">
                  <BlogImg
                    image={
                      node.frontmatter.featuredImage.childImageSharp
                        .gatsbyImageData
                    }
                    alt={
                      node.frontmatter.featuredImageAlt
                        ? node.frontmatter.featuredImageAlt
                        : node.frontmatter.title
                    }
                  />
                </ContentBox>
              )}
              <ContentBox padding="1.5em" margin="0">
                <Link to={`/blog/${node.frontmatter.slug}/`}>
                  <BlogTitle>{node.frontmatter.title}</BlogTitle>
                </Link>
                <P color="black">{node.excerpt}</P>
                {
                  //<p>{node.frontmatter.date}</p>
                }
              </ContentBox>
            </PostGrid>
          ))}
          <BlogNav>
            {currentPage > 1 && (
              <Link
                to={`/blog/${
                  currentPage - 1 === 1 ? "" : `${currentPage - 1}/`
                }`}
              >
                « Seite {currentPage - 1} von {numPages}
              </Link>
            )}
            {currentPage < numPages && (
              <BlogPrev to={`/blog/${currentPage + 1}/`}>
                Seite {currentPage + 1} von {numPages} »
              </BlogPrev>
            )}
          </BlogNav>
        </Container>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
